<template>
  <div style="" class="minheight500">
  <bannerStatic :num="4" :title="'把握智能制造大势，助力制造业转型升级'"/>
    <div class="newDetails-box">
        <a-breadcrumb class="width1000" style="text-align:left;margin:20px auto 40px;">
            <a-breadcrumb-item> <router-link :to="`/`">首页</router-link></a-breadcrumb-item>
            <a-breadcrumb-item><router-link :to="`/News`">新闻动态</router-link></a-breadcrumb-item>
             <a-breadcrumb-item style="color:rgba(0, 0, 0, 0.45)">新闻详情</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="width1000">
             <div class="article-box" v-html="content.newsContent">
            </div>
        </div>
    </div>
    <router-link class="more" to="/News">新闻列表</router-link>
  </div>
</template>
<script>
import bannerStatic from'@/components/BannerStatic/BannerStatic'
import { getNewDetails } from '@/api/data'
import  { newsImg } from '@/config/appSetting'
export default {
  name: 'NewDetails',
    components:{
      bannerStatic,
  },
  data() {
      return {
          imgUrl:'',
          positionY:0,
          content:{},
          newsImg
      }
  },
  mounted() {
     getNewDetails({},this.$route.params.id).then(res => {
        if (res.state) {
          this.content = res.data ? res.data : {}
          this.imgUrl= newsImg+this.content.newsImageUrl
        }
     })
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
        let scrollTop =window.pageYOffset ||document.documentElement.scrollTop ||document.body.scrollTop;
        this.positionY = 740 - scrollTop * 0.25; 
    }
  },
  beforeDestroy() {
    this.positionY=0
    window.removeEventListener("scroll", this.handleScroll);
  },
}
</script>

<style scoped>
.newDetails-box{
    position: relative;
    padding-top:20px;
    background: #fff;
    text-align: left;
    min-height: 600px;
}
.newDetails-box h3{
    font-size: 40px;
    line-height: 48px;
}
.abstract-box{
  text-align: left;
  font-size: 16px;
  text-indent: 40px;
  line-height: 30px;
  margin:0px 0 60px;
}
.more{
  display: inline-block;
  width: 166px;
  height: 40px;
  border: solid 1px #999;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  color: #666;
  margin:30px auto;
}
.width1000{
  width:1000px;
  margin:0 auto;
}
</style>
